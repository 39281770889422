<template>
  <v-dialog
    class="mem-dialog"
    v-model="dialog"
    :max-width="360"
    overlay-color="#000000"
    overlay-opacity="0.54"
  >
    <div class="checkout-error">
      <i class="material-icons close-icon" @click="$emit('input', false)">
        close
      </i>

      <i class="material-icons error-icon"> error_outline </i>
      <div class="error-title">{{ modalTitle }}</div>
      <div class="error-subline" v-if="subline">
        {{ modalSubline }}
      </div>
      <template>
        <mem-button v-if="showRefreshButton" @click="confirmRefresh">{{
          $t("try_again")
        }}</mem-button>
        <mem-button @click="$emit('input', false)" v-else>OK</mem-button>
      </template>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    refreshSources: ["get-order", "get-order-describe", "post-order"],
  }),
  props: {
    value: Boolean,
    title: String,
    subline: null,
    source: null,
  },
  computed: {
    ...mapGetters(["isLoadedStatus"]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    showRefreshButton() {
      return this.refreshSources.includes(this.source);
    },
    // i18nIsLoaded() {
    //   return this.isLoadedStatus.userLocaleTranslations;
    // },
    modalTitle() {
      return this.$t(this.title);
    },
    modalSubline() {
      return this.$t(this.subline);
    },
  },
  methods: {
    confirmRefresh() {
      this.$emit("on-refresh-confirmation", this.source);
      this.$emit("input", false);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.checkout-error {
  position: relative;
  background-color: #ffffff;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 52px;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;

  .close-icon {
    position: absolute;
    font-size: 24px;
    color: #d3d3d3;
    right: 6px;
    top: 8px;
    @include cursorPointer;
  }
  .error-icon {
    font-size: 48px;
    margin-bottom: 20px;
  }
  .error-title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    max-width: 242px;
  }
  .error-subline {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    max-width: 274px;
  }
  button {
    height: 36px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.04em;
    width: 100%;
  }
}
</style>